import React, { useState } from 'react';
import { Image, Typography, Spin, message, Upload } from 'antd';
import axios from 'axios';
import { CameraOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import Compress from 'react-image-file-resizer';
import converter from '../../utils/converterBase64ToBinary';
import './verify.scss';
import { BASE_URL } from '../../utils/constant';

const Camera = ({ type, onDispatch }) => {
  const [chooseImage, setChooseImage] = useState(localStorage.getItem(type));
  const [loading, setLoading] = useState(false);

  if (chooseImage) {
    onDispatch(chooseImage);
  }

  async function onChange(file) {
    try {
      setLoading(true);

      let resizedImage;
      if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
        resizedImage = await new Promise((resolve) => {
          Compress.imageFileResizer(
            file,
            300,
            300,
            'JPEG',
            300,
            0,
            (resizedFile) => {
              resolve(resizedFile);
            },
            'base64'
          );
        });
      } else if (file.type === 'image/png') {
        resizedImage = await new Promise((resolve) => {
          Compress.imageFileResizer(
            file,
            300,
            300,
            'PNG',
            300,
            0,
            (resizedFile) => {
              resolve(resizedFile);
            },
            'base64'
          );
        });
      }

      const formData = new FormData();
      formData.append('file', converter(resizedImage));

      const { data } = await axios.post(`${BASE_URL}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      const endpoint = data.secure_url.replace(
        'res.cloudinary.com',
        'res.cloudinary.com'
      );

      localStorage.setItem(type, endpoint);
      setChooseImage(endpoint);
      onDispatch(endpoint);
    } catch (err) {
      message.error('Đã có lỗi trong việc upload ảnh. Vui lòng thử lại');
    } finally {
      setLoading(false);
    }
  }

  return (
    <motion.div
      className="image-container"
      style={{
        backgroundImage: chooseImage ? `url(${chooseImage})` : 'none',
      }}
      whileTap={{ scale: 0.98, opacity: 0.3 }}
    >
      <Upload
        accept="image/*"
        showUploadList={false}
        beforeUpload={onChange}
        style={{ textAlign: 'center' }}
      >
        {!chooseImage ? (
          <div style={{ textAlign: 'center' }}>
            <CameraOutlined style={{ fontSize: 30, color: '#333' }} />
            <br />
            {type === 'front' && (
              <Typography.Text style={{ fontWeight: 700, fontSize: 16 }}>
                Mặt trước CMND / Thẻ cư trú
              </Typography.Text>
            )}
            {type === 'back' && (
              <Typography.Text style={{ fontWeight: 700, fontSize: 16 }}>
                Mặt trước hộ chiếu (Passport)
              </Typography.Text>
            )}
            {type === 'face' && (
              <Typography.Text style={{ fontWeight: 700, fontSize: 16 }}>
                Ảnh chân dung
              </Typography.Text>
            )}
          </div>
        ) : (
          <div
            style={{
              width: '200px',
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                height: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image src={chooseImage} style={{ height: '100%' }} />
            </div>
          </div>
        )}
      </Upload>
      {loading && <Spin spinning />}
    </motion.div>
  );
};

const Verify = ({ onOk }) => {
  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const [face, setFace] = useState(null);

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="user-img-container"
    >
      <Typography.Text strong style={{ fontSize: 18, padding: 5 }}>
        Chụp ảnh định danh KYC
      </Typography.Text>
      <Camera type="front" onDispatch={(e) => setFront(e)} />
      <Camera type="back" onDispatch={(e) => setBack(e)} />
      <Camera type="face" onDispatch={(e) => setFace(e)} />
      <motion.div
        whileTap={{ opacity: 0.4, scale: 0.97 }}
        className="confirm-div"
        onClick={() => {
          if (front && back && face) onOk({ front, back, face });
          else message.error('Vui lòng cung cấp đủ thông tin');
        }}
      >
        <Typography.Text
          style={{ color: '#fff', fontWeight: 700, fontSize: 17 }}
        >
          Tiếp tục
        </Typography.Text>
      </motion.div>
    </motion.div>
  );
};

export default Verify;
